import { GetStaticProps } from 'next';
import { getDestination } from '../../../src/entities/Attractions/service/Creators';
import { AttractionsPage } from '../../../src/screens/AttractionsPage/ui/AttractionsPage';
import { TPropsAttractionsPage } from '@/entities/Attractions/domain/Attractions.domain';
import { getRedirectsPath } from '@/common/service/api/Redirects/getRedirectsPath';

export async function getStaticPaths() {
    return {
        paths: [],
        fallback: 'blocking',
    };
}

type PageStaticProps = {
    destination: string;
};

export const getStaticProps: GetStaticProps<TPropsAttractionsPage, PageStaticProps> = async ({
    params,
}) => {
    try {
        if (!params) {
            throw new Error(`PAGE THINGS TO DO /DESTINATION/CATEGORY: BAD PARAMS: ${params}`);
        }

        const currentDestination = getDestination(params.destination);
        const currentPath = `/attractions/${params.destination}/`;

        if (!currentDestination) {
            const redirectPath = await getRedirectsPath(currentPath);

            if(redirectPath === '/404/'){
                return {
                    notFound: true,
                };
            }
            
            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect: {
                    destination: redirectPath.toLowerCase(),
                    statusCode: 301,
                },
            };
        }

        return {
            revalidate: Number(process.env.CACHE_ENV),
            redirect: {
                destination:
                    `/destination/${currentDestination.state.code}/${currentDestination.slug}/things-to-do/`.toLowerCase(),
                statusCode: 301,
            },
        };
    } catch (e) {
        throw new Error((e as Error).message);
    }
};

export default AttractionsPage;
